<template>
  <div>
    <b-row>
      <b-col>
        <p>LAT:</p>
        <p>{{ marker.position.lat }}</p>
      </b-col>
      <b-col>
        <p>LNG:</p>
        <p>{{ marker.position.lng }}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <GmapMap
          :center="marker.position"
          :zoom="zoomLevel"
          map-type-id="terrain"
          style="width: 100%; height: 400px"
        >
          <GmapMarker
            :position="marker.position"
            :clickable="true"
            :draggable="true"
            @dragend="updateCoordinates"
          />
        </GmapMap>
        <gmap-autocomplete
          placeholder="Testplaceholder"
          @place_changed="updatePlace"
        >
        </gmap-autocomplete>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="text-center">
        <b-button
          v-if="!saveInProgress"
          ref="savecoordinatesbutton"
          variant="primary"
          size="lg"
          class="margin-top-26 mx-auto my-5"
          @click="save"
        >
          {{ trans('prospects.landingpage.maps_block.save') }}
        </b-button>
        <b-button
          v-else
          variant="primary"
          size="lg"
          class="margin-top-26 mx-auto my-5"
          :style="{ width: saveButtonWidth }"
        >
          <b-spinner variant="white" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'

  const fb = require('../../../../../../../firebaseConfig')

  Vue.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_API,
      libraries: 'places'
    }
  })

  export default {
    name: 'LandingpageBlockMaps',
    props: {
      block: {
        type: Object,
        required: true
      },
      project: {
        type: Object,
        required: true
      },
      prospect: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        marker: {
          position: {
            lat: this.block.data !== undefined && this.block.data.coordinates !== undefined ? this.block.data.coordinates.lat : 60.47202399999999,
            lng: this.block.data !== undefined && this.block.data.coordinates !== undefined ? this.block.data.coordinates.lng : 8.46894599999996
          }
        },
        saveInProgress: false,
        saveButtonWidth: false,
        zoomLevel: this.block.data !== undefined && this.block.data.coordinates !== undefined ? 16 : 6
      }
    },
    methods: {
      updateCoordinates (coords) {
        this.$set(this.marker, 'position', { lat: coords.latLng.lat(), lng: coords.latLng.lng() })
      },
      updatePlace (place) {
        this.$set(this.marker, 'position', { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
      },
      save () {
        const self = this
        this.saveButtonWidth = this.$refs.savecoordinatesbutton ? (parseInt(this.$refs.savecoordinatesbutton.clientWidth) + 2).toString() + 'px' : false
        this.saveInProgress = true

        fb.prospectsCollection.doc(this.prospect._id).update({
          [`landingpage.blocks.${self.block.key}.data.coordinates`]: self.marker.position
        }).then(() => {
          self.$bvToast.toast(self.trans('prospects.landingpage.maps_block.add_coordinates_success'), {
            title: self.trans('global.success'),
            variant: 'success',
            solid: true
          })
          this.saveInProgress = false
        }).catch((err) => {
          self.$bvToast.toast(self.trans('prospects.landingpage.maps_block.add_coordinates_error'), {
            title: self.trans('global.error'),
            variant: 'warning',
            solid: true
          })
          this.saveInProgress = false
          console.log('Error while saving coordinates:')
          console.log(err)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
